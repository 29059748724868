import type { Product } from "common/types";

export default function getStandardGradient(standard: Product["standard"]) {
  switch (standard) {
    case "Premium":
      return "from-amber-200/50 via-amber-200/20";
    case "Wysoka":
      return "from-purple-400/50 via-purple-400/20";
    default:
      return "";
  }
}
