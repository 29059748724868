import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";

import FullScreen from "./FullScreen";

interface GalleryProps {
  images: Array<any>;
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const [showFullScreen, setShowFullScreen] = useState<boolean>(false);

  const handleModalOpen = () => {
    setShowFullScreen(true);
  };

  return (
    <>
      <div className="cursor-pointer" onClick={handleModalOpen}>
        <GatsbyImage
          image={images[0].childImageSharp.gatsbyImageData}
          alt="Prezentacja przedmiotu"
          imgClassName="rounded-xl"
          className="h-[300px] lg:h-[400px]"
        />

        <div className="mt-4 grid grid-cols-2 gap-4 lg:grid-cols-3">
          {images.slice(1).map((image, index) => (
            <GatsbyImage
              key={index}
              image={image.childImageSharp.gatsbyImageData}
              alt={index.toString()}
              className="h-[96px] lg:h-[140px]"
              imgClassName="rounded-xl"
              objectFit="cover"
              objectPosition="center"
            />
          ))}
        </div>
      </div>

      <FullScreen
        show={showFullScreen}
        images={images}
        setShowFullScreen={setShowFullScreen}
      />
    </>
  );
};

export default Gallery;
