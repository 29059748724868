import { Dialog } from "@headlessui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { type Dispatch, type SetStateAction } from "react";

interface FullScreenProps {
  images: Array<any>;
  show: boolean;
  setShowFullScreen: Dispatch<SetStateAction<boolean>>;
}

const FullScreen: React.FC<FullScreenProps> = ({
  images,
  show,
  setShowFullScreen,
}) => {
  const handleModalClose = () => {
    setShowFullScreen(false);
  };

  return (
    <Dialog open={show} onClose={handleModalClose} className="relative z-50">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/80" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="relative flex h-[480px] w-[600px] snap-y flex-col space-y-4 overflow-y-scroll rounded-xl md:h-[800px] md:w-[800px]">
          {images.map((image, index) => (
            <GatsbyImage
              key={index}
              alt={index.toString()}
              image={image.childImageSharp.gatsbyImageData}
              imgStyle={{ objectFit: "contain", borderRadius: "0.75rem" }}
              className="h-[92%] flex-shrink-0 snap-center"
            />
          ))}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default FullScreen;
