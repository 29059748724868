import { MusicNoteIcon, ShoppingBagIcon } from "@heroicons/react/outline";
import BackButton from "common/components/BackButton";
import Button from "common/components/Button";
import Container from "common/components/Container";
import InfoChip from "common/components/InfoChip";
import SEO from "common/components/SEO";
import DefaultLayout from "common/layouts/Default";
import { graphql } from "gatsby";
import React from "react";

import Heading from "../components/Heading";
import Dimensions from "./Dimensions";
import Gallery from "./Gallery";
import PianoDetails from "./PianoDetails";

interface ProductTemplateProps {
  data: any;
}

const ProductTemplate: React.FC<ProductTemplateProps> = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  return (
    <DefaultLayout>
      <SEO title={frontmatter.title} />

      <Container customClasses="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
        <div>
          <BackButton to="/sklep" />
          <Gallery images={frontmatter.images} />
        </div>

        <section className="flex flex-col justify-between gap-10">
          <Heading product={frontmatter} />

          <PianoDetails frontmatter={frontmatter} />

          <InfoChip
            title="Cechy dźwięku"
            color="orange"
            icon={<MusicNoteIcon className="h-5 w-5" />}
            content={frontmatter.sound.join(", ")}
          />

          <Dimensions frontmatter={frontmatter} />

          <p className="text-zinc-600">
            Dodatkowych informacji udzielę telefonicznie lub mailowo.
          </p>

          <Button
            customClasses="flex self-end items-center justify-center gap-2 !bg-indigo-600 h-12"
            to={"/sklep/" + frontmatter.sku + "/kup"}
          >
            <ShoppingBagIcon className="h-5 w-5" />
            Kup teraz
          </Button>
        </section>
      </Container>
    </DefaultLayout>
  );
};

export default ProductTemplate;

export const data = graphql`
  query PRODUCT_TEMPLATE_QUERY($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 1000)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        location
        type
        title
        manufacture {
          built
          countryOfOrigin
          brandOrigin
        }
        condition
        look {
          color
          finish
        }
        sound
        standard
        price
        currency
        sku
        dimensions {
          height
          length
          width
        }
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
