import type { Product } from "common/types";

export default function getConditionGradient(condition: Product["condition"]) {
  switch (condition) {
    case "Perfekcyjny" || "Nowy":
      return "from-amber-200/50 via-amber-200/20";
    case "Bardzo dobry":
      return "from-green-200/50 via-green-200/20";
    default:
      return "";
  }
}
