import { ArrowUpIcon, SwitchHorizontalIcon } from "@heroicons/react/solid";
import InfoChip from "common/components/InfoChip";
import React from "react";

interface DimensionsProps {
  frontmatter: any;
}

const Dimensions: React.FC<DimensionsProps> = ({ frontmatter }) => {
  const infoChips = [
    {
      title: "Wysokość",
      content: frontmatter.dimensions.height + " cm",
      icon: <ArrowUpIcon className="h-3 w-3 text-inherit" />,
      color: "zinc",
    },
    {
      title: "Szerokość",
      content: frontmatter.dimensions.width + " cm",
      icon: <SwitchHorizontalIcon className="h-3 w-3 text-inherit" />,
      color: "zinc",
    },
    {
      title: "Długość",
      content: frontmatter.dimensions.length + " cm",
      icon: (
        <ArrowUpIcon
          className="h-4 w-4 text-inherit"
          style={{ transform: "rotate3d(-0.16, 1, 0.62, 3.142rad)" }}
        />
      ),
      color: "zinc",
    },
  ];

  return (
    <div role="group" aria-labelledby="dimensionsLabel">
      <p className="mb-4 text-xl font-semibold" id="dimensionsLabel">
        Wymiary
      </p>
      <div className="grid grid-cols-2 gap-y-6 sm:gap-y-4">
        {infoChips.map(({ title, content, icon, color }) => (
          <InfoChip
            key={title}
            title={title}
            content={content}
            icon={icon}
            color={color}
          />
        ))}
      </div>
    </div>
  );
};

export default Dimensions;
