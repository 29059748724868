import {
  BadgeCheckIcon,
  CalendarIcon,
  ColorSwatchIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import React from "react";

import assignInfoCustomClasses from "./assignInfoCustomClasses";
import Info from "./Info";

interface PianoDetailsProps {
  frontmatter: any;
}

const PianoDetails: React.FC<PianoDetailsProps> = ({ frontmatter }) => {
  const infoCards = [
    {
      title: "Klasa instrumentu",
      icon: <SparklesIcon className="h-5 w-5 text-amber-300" />,
      content: frontmatter.standard,
    },
    {
      title: "Stan",
      icon: <BadgeCheckIcon className="h-5 w-5 text-green-300" />,
      content: frontmatter.condition,
    },
  ];

  const info = [
    {
      title: "Rok budowy",
      icon: <CalendarIcon className="h-5 w-5 text-blue-300" />,
      content: frontmatter.manufacture.built,
    },

    {
      title: "Kraj pochodzenia",
      content: frontmatter.manufacture.brandOrigin,
    },
    {
      title: "Kraj produkcji",
      content: frontmatter.manufacture.countryOfOrigin,
    },
    {
      title: "Kolor",
      icon: <ColorSwatchIcon className="h-5 w-5 text-pink-300" />,
      content: (
        <>
          <p>{frontmatter.look.color}</p>
          <p className="text-sm text-zinc-300">
            Wykończenie: {frontmatter.look.finish.toLowerCase()}
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2">
      {infoCards.map(({ title, icon, content }) => (
        <Info
          key={title}
          title={title}
          icon={icon}
          customClasses={`col-span-2 lg:col-span-1 ${assignInfoCustomClasses(
            title,
            content
          )}`}
        >
          {content}
        </Info>
      ))}
      <div className="col-span-2 mt-2 grid w-full grid-cols-2 gap-4">
        {info.map(({ title, icon, content }) => (
          <Info
            key={title}
            title={title}
            icon={icon}
            customClasses="!py-0 bg-none"
          >
            {content}
          </Info>
        ))}
      </div>
    </div>
  );
};

export default PianoDetails;
