import React from "react";

interface InfoProps {
  title: string;
  children: React.ReactNode;
  icon?: JSX.Element;
  customClasses?: string;
}

const Info: React.FC<InfoProps> = ({
  title,
  children,
  icon,
  customClasses,
}) => {
  return (
    <div
      className={`rounded-xl bg-gradient-to-br from-zinc-700/80 to-transparent p-4 lg:px-6 ${customClasses}`}
    >
      <span className="mb-1 flex items-center gap-1 text-zinc-300">
        {icon}
        {title}
      </span>

      <span className="text-xl lg:text-2xl">{children}</span>
    </div>
  );
};

export default Info;
