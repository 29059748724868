import type { Product } from "common/types";

import getConditionGradient from "./getConditionGradient";
import getStandardGradient from "./getStandardGradient";

export default function assignInfoCustomClasses(
  title: string,
  content: Product["standard"] | Product["condition"]
) {
  switch (title) {
    case "Klasa instrumentu":
      return getStandardGradient(content as Product["standard"]);
    case "Stan":
      return getConditionGradient(content as Product["condition"]);
  }
}
